import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';

const BramhaSponsor = () => {
    return (
        <>
            <SEO title="Brahmotsavam Sponsor" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Brahmotsavam Sponsor</h3>
                <Pageheader />
                <p className=" text-justify" >Brahmotsavam donations can be made by Paypal/Credit Card/Debit Card using the Donate button at the end of this page.  Please mention ‘Brahmotsavam Donation’ in the space for Write a Note.</p>
                <h5 className=" font-weight-bold text-justify text-primary">Please complete the below form by filling in your sponsorship choices, details for Sankalpam, contact information & payment.  - <a href="https://forms.gle/AdQATkEWZjUgH8Au5" target="_blank" rel="noopener noreferrer" className="font-weight-bold">"Brahmotsavam Sponsor"</a></h5>
                <p className=" text-justify" >If you would like to write a cheque for the occasion, please make it in favor of “Hindu Temple Association of Eastern Iowa” and mail to PO Box.10573, Cedar Rapids, IA 52410-0573.  Please mention ‘Brahmotsavam Donation’ in the memo.</p>
                {/* <iframe title="bigsponsor" src="https://docs.google.com/forms/d/e/1FAIpQLSfDPVB6HH6gUIyIbXtOYedI7aywcoc9-DJA-zK5NygZ1x96jg/viewform?embedded=true" width="100%" style={{ minHeight: "2000px" }} frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe> */}
            </MDBContainer>
        </>
    );
};

export default BramhaSponsor;